import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/organisms/seo"
import Layout from "../components/layout-index"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const edges = data.allMarkdownRemark.edges

    return (
      <>
        <SEO title={`${siteTitle} | 学んだ技術を記録しています`} />
        <Layout
          location={this.props.location}
          title={siteTitle}
          edges={edges}
          >
        </Layout>
      </>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark (
      sort: { fields: [fields___slug], order: DESC }
      limit:20
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            digest
            category
            tag
            img {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            giphy
          }
          parent {
            ... on File {
              birthTime(formatString: "YYYY-MM-DD")
              modifiedTime(formatString: "YYYY-MM-DD")
            }
          }
        }
      }
    }
  }
`
