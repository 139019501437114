import React from "react"
// import { rhythm } from "../utils/typography"
import Footer from "./atoms/footer"
import Sitename from "./moleculs/siteTitle"
import Nav from "./organisms/navigation"
import Hero from "./organisms/hero"
import GridCards from "./organisms/gridCards"
import Bio from "./organisms/bio"
import {ProvideMediaMatchers, MediaMatcher} from "react-media-match"


class Layout extends React.Component {
  render() {
    const { location, title, edges } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <>
        <header>
          <Sitename location={location} rootPath={rootPath} />
          <Nav className={`navi text-4xl`} />
        </header>

        {/*<Hero></Hero>*/}

        <main>
          {/*<ProvideMediaMatchers>
            <MediaMatcher
              mobile={<Bio />}
              tablet={"tablet"}
              desktop={"render desktop"}
              />
          </ProvideMediaMatchers>*/}
          <div className="w-5/6 mx-auto sm:w-1/2 lg:w-1/2">
            <Bio />
            <GridCards edges={edges} />
          </div>
        </main>
        <div className="skewer"></div>
        <Footer />
      </>
    )
  }
}

export default Layout
